var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        staticClass: "no-data px-5 py-4 w-full",
        attrs: { "data-testid": "asterix-no-data" },
      },
      [
        !_vm.hideImage
          ? _vm._t("icon", function () {
              return [
                _c(
                  "div",
                  { staticClass: "w-1/2 lg:w-3/12 m-auto mb-12 mt-6" },
                  [_c("empty-search")],
                  1
                ),
              ]
            })
          : _vm._e(),
        _c(
          "p",
          {
            staticClass:
              "text-base sm:text-lg lg:text-xl xl:text-2xl text-orange-500 cursor-default font-bold",
          },
          [
            _vm._t("title", function () {
              return [_vm._v(" " + _vm._s(_vm.title) + " ")]
            }),
          ],
          2
        ),
        _c(
          "p",
          {
            staticClass:
              "mt-2 m-6 text-sm sm:text-base text-gray-600 font-bold cursor-default",
          },
          [
            _vm._t("subtitle", function () {
              return [_vm._v(" " + _vm._s(_vm.subtitle) + " ")]
            }),
          ],
          2
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }