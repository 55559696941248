<template>
  <transition name="fade">
    <div class="no-data px-5 py-4 w-full" data-testid="asterix-no-data">
      <slot v-if="!hideImage" name="icon">
        <div class="w-1/2 lg:w-3/12 m-auto mb-12 mt-6">
          <empty-search />
        </div>
      </slot>

      <p class="text-base sm:text-lg lg:text-xl xl:text-2xl text-orange-500 cursor-default font-bold">
        <slot name="title">
          {{ title }}
        </slot>
      </p>

      <p class="mt-2 m-6 text-sm sm:text-base text-gray-600 font-bold cursor-default">
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </p>
    </div>
  </transition>
</template>

<script>
import EmptySearch from '@/components/icons/EmptySearch';

export default {
  name: 'AsterixNoData',
  components: { EmptySearch },
  props: {
    title: {
      type: String,
      default: `We couldn't find any result for your search`,
    },
    subtitle: {
      type: String,
      default: 'Try again using different filters or search terms.',
    },
    hideImage: {
      type: Boolean,
      default: () => false,
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
